<template>
  <div>
    <img src="@/assets/logo.jpg" height="50" width="75" />
    <v-container style="padding-top: 4rem">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Teambeheren' }">
            <div class="link-box">
              <img src="@/assets/dashboard/doctor.png" alt="img" />
              <p class="link-box-title">Medewerkers toevoegen</p>
              <p class="link-box-subtitle">Medewerkers toevoegen en beheren</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Content' }">
            <div class="link-box">
              <img src="@/assets/dashboard/creator.png" alt="img" />
              <p class="link-box-title">Content beheren</p>
              <p class="link-box-subtitle">Content van de slider beheren</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'QRgenerator' }">
            <div class="link-box">
              <img src="@/assets/dashboard/qrcode.png" alt="img" />
              <p class="link-box-title">QR code genereren</p>
              <p class="link-box-subtitle">QR code genereren en downloaden</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link
            class="router-link"
            :to="{ name: 'WebsiteContentBeheren' }"
          >
            <div class="link-box">
              <img src="@/assets/writer.png" alt="img" />
              <p class="link-box-title">Website tekst beheren</p>
              <p class="link-box-subtitle">Tekst van de website beheren</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link
            class="router-link"
            :to="{ name: 'WebsiteBehandelingenBeheren' }"
          >
            <div class="link-box">
              <img src="@/assets/document.png" alt="img" />
              <p class="link-box-title">Behandelingen beheren</p>
              <p class="link-box-subtitle">Website behandelingen beheren</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Folders' }">
            <div class="link-box">
              <img src="@/assets/folder.png" alt="img" />
              <p class="link-box-title">Folders</p>
              <p class="link-box-subtitle">Folder documenten beheren</p>
            </div>
          </router-link>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showToolbar: true,
      company: "Welkom bij Waldent Digital Signage",
    };
  },
  methods: {
    toggleToolbar() {
      this.showToolbar = !this.showToolbar;
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  text-align: center;
}
.link-box:hover {
  transform: scale(1.05);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}
.link-box-title,
.link-box-subtitle {
  transition: color 0.3s ease;
}
.link-box:hover .link-box-title {
  color: #0056b3; /* Verandert de kleur van de titel bij hover */
}

.link-box:hover .link-box-subtitle {
  color: #004494; /* Donkerder kleur voor de ondertitel bij hover */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 50px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 20px;
}
.link-box-subtitle {
  color: #555;
  font-size: 16px;
}
</style>
