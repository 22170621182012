<template>
  <Folders />
</template>

<script>
import Folders from "@/components/dashboard/Folders";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Folders,
  },
};
</script>
