import { API } from "./api.base.js";

export default {
  async upLoad() {
    try {
      const response = await API.get("/api/rest/v2/waldent/folders/upload");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getAllDocumets() {
    try {
      const response = await API.get("/api/rest/v2/waldent/folders/list");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteDocument(fileName) {
    try {
      const response = await API.get(
        `/api/rest/v2/waldent/folders/delete/documenten/${fileName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
