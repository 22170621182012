import { API } from "./api.base.js";

export default {
  async getTimer() {
    try {
      const response = await API.get("/api/rest/v2/admin/timer");
      return response.data.timer;
    } catch (error) {
      console.error(error);
    }
  },

  async updateTimer(data) {
    try {
      const response = await API.put(`/api/rest/v2/admin/timer`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
