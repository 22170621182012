<template>
  <v-toolbar color="blue" elevation="4" flat outlined>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon style="margin-left: 2px">
          <v-btn dark v-bind="attrs" v-on="on"> Menu </v-btn>
        </v-app-bar-nav-icon>
        <Settings />
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          @click="closeMenu"
        >
          <v-list-item-title>
            <router-link :to="item.route">
              {{ item.text }}
            </router-link>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import Settings from "./Settings.vue";
export default {
  components: {
    Settings,
  },
  data() {
    return {
      // Hier voeg je je menu-items toe
      menuItems: [
        { text: "Home", route: "/dashboard" },
        { text: "Content beheren", route: "/Content" },
        { text: "Medewerkersbeheren", route: "/Teambeheren" },
        { text: "QRcode maken", route: "/contQRgeneratoract" },
        {
          text: "Diensten teksten bewerken",
          route: "/WebsiteBehandelingenBeheren",
        },
        { text: "Webstite teksten bewerken", route: "/WebsiteContentBeheren" },
        { text: "Folders beheren", route: "/Folders" },
      ],
      menu: false,
    };
  },
  methods: {
    closeMenu() {
      this.menu = false;
    },
  },
};
</script>
