<template>
  <div>
    <Menu />
    <v-container>
      <div class="toolbar"></div>

      <v-row class="justify-center">
        <v-col cols="12" md="6" style="margin-bottom: 5px; display: flex">
          <v-text-field
            dense
            v-model="searchQuery"
            append-icon="mdi-magnify"
            label="Zoeken content"
            hide-details
            outlined
            class="search-field"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="field in filteredContentFields"
          :key="field.id"
          class="compact-panel"
        >
          <v-expansion-panel-header>
            {{ field.name }}
            <v-spacer></v-spacer>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row style="margin-top: 5px">
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model="field.name"
                  outlined
                  label="Titel"
                ></v-text-field>
              </v-col>
            </v-row>

            <vue-editor
              v-model="field.content"
              :id="field.id.toString()"
            ></vue-editor>
            <v-btn @click="updateIndividualContent(field)" color="primary" block
              >Update {{ field.name }}</v-btn
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="action-buttons"></div>
    </v-container>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import apiContent from "@/components/api/apiWebsite";
import Menu from "@/components/dashboard/Menu.vue";
export default {
  components: {
    VueEditor,
    Menu,
  },
  data() {
    return {
      selectedImageFiles: [],
      searchQuery: "",
      visible: false,
      highestOrder: 0,
      contentFields: [],
      nextFieldId: 1,
    };
  },
  computed: {
    filteredContentFields() {
      if (!this.searchQuery) return this.contentFields;

      const searchLowerCase = this.searchQuery.toLowerCase();

      return this.contentFields.filter((field) =>
        field.naam.toLowerCase().includes(searchLowerCase)
      );
    },
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        const data = await apiContent.haalContentOp();
        if (Array.isArray(data)) {
          this.contentFields = data.map((field) => ({
            id: field.id,
            name: field.name,
            title: field.title,
            content: field.content,
          }));
          this.nextFieldId = Math.max(...data.map((field) => field.id)) + 1;
          this.highestOrder = Math.max(...data.map((field) => field.order));
        }
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    },

    async updateIndividualContent(field) {
      try {
        const data = {
          title: field.name,
          name: field.name,
          content: field.content,
        };
        await apiContent.updateContent(field.id, data);
        await this.fetchData();
        this.$swal.fire({
          title: "Content is geupdate",
          text: `Content met ID ${field.name} is succesvol geupdate.`,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } catch (error) {
        this.$swal.fire({
          title: "Houston, we have a problem!",
          text: `Error message: ${error.message}`,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}
#editor {
  height: 350px;
  width: 150px;
}
</style>
