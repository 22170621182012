<template>
  <div>
    <Menu />
    <v-container>
      <v-card class="pa-5" elevation="5">
        <v-card-title class="headline">Upload Bestanden</v-card-title>
        <v-card-subtitle
          >Beheer en upload je bestanden eenvoudig</v-card-subtitle
        >

        <v-divider class="my-4"></v-divider>

        <div class="text-center">
          <input
            type="file"
            ref="fileInput"
            @change="handleFileUpload"
            hidden
          />
          <v-btn color="primary" @click="triggerFileInput">
            <v-icon left>mdi-upload</v-icon> Bestand uploaden
          </v-btn>
        </div>

        <v-progress-linear
          v-if="uploading"
          indeterminate
          color="blue lighten-2"
          class="my-4"
        ></v-progress-linear>
      </v-card>

      <v-card class="mt-5" elevation="3">
        <v-card-title>Bestandenlijst</v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="files"
          class="elevation-1 mt-3"
        >
          <template v-slot:item.url="{ item }">
            <a :href="item.url" target="_blank" class="text-decoration-none">
              <v-icon small color="blue">mdi-link</v-icon> Open bestand
            </a>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon small @click="copyToClipboard(item.url)">
              <v-icon color="green">mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <v-snackbar v-model="snackbar" :timeout="2000" color="success">
        Link gekopieerd naar klembord!
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
import axios from "axios";
import apiFolder from "../api/apiFolder";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      files: [],
      uploading: false,
      snackbar: false,
      headers: [
        { text: "Bestandsnaam", value: "name", sortable: false },
        { text: "URL", value: "url", sortable: false },
        { text: "Acties", value: "actions", sortable: false },
      ],
    };
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        const response = await apiFolder.getAllDocumets();
        console.log("Ophalen bestanden:", response.data);
        this.files = response;
      } catch (error) {
        console.error("Fout bij ophalen van bestanden:", error);
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    async handleFileUpload(event) {
      const files = event.target.files;
      if (!files.length) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }

      this.uploading = true;
      try {
        const response = await axios.post(
          "http://localhost:3002/api/rest/v2/waldent/folders/upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        console.log("Bestanden succesvol geüpload:", response.data);
        this.fetchData(); // Opnieuw laden na upload
      } catch (error) {
        console.error("Fout bij uploaden van bestanden:", error);
      }
      this.uploading = false;
    },

    copyToClipboard(url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Fout bij kopiëren van link:", error);
        });
    },
  },
};
</script>

<style scoped>
h2,
h3 {
  margin: 10px 0;
}
</style>
