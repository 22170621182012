import { API } from "./api.base.js";

export default {
  async haalContentOp() {
    try {
      const response = await API.get("/api/rest/v2/servicemanagement");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateContent(id, data) {
    try {
      const response = await API.put(
        `/api/rest/v2/servicemanagement/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
